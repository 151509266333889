import { React, useContext,useEffect,useState } from "react";
import { Container, Card, Button, Row, Col, Badge,Alert,ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppContext } from "../App";
import { useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
import { signOut,onAuthStateChanged } from "firebase/auth";
import { auth,functions,getTokenAppCheck } from "../firebaseconfig";
import { httpsCallable} from "firebase/functions";
import BuyModal from "./buymodal";

const BuyTac = () => {
 
  const navigate = useNavigate();
  const { state ,dispatch} = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [soldtokens, setSoldTokens] = useState("");  
  const [totaltokens, setTotalTokens] = useState("");
  const [status, setStatus] = useState("");
  const [pendingTransactions, setPendingTransactions] = useState("");
  
  const [desc, setDesc] = useState("");
  const [balance, setBalance] = useState(0);
  const [buyAmount, setBuyAmount] = useState(0);  
  const [buyStep, setBuyStep] = useState(0);  
  const [balanceInUsdt, setBalanceInUsdt] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
      if (state.user.email && state.user.email.length > 0) {
      setModalOpen(true);
      }else{
        navigate("/login");
      } 
    }
    const closeModal = () => {
      setBuyAmount(0);
      setBuyStep(0);
      setModalOpen(false);
    }
  const [price, setPrice] = useState(0);
  const [stage, setStage] = useState("");
  const [isRunning, setIsRunning] = useState(false);

  const qrback='#FFFFFF';
  const qrfore='#000000';
  const qrsize=128;
  const Logout = () => {
    signOut(auth).then(() => {
    dispatch({type:'isLogon', payload:false});
    dispatch({type:'user',payload:{'email':'','uid':''}});
    navigate("/");
    });
  }
  const onBuy = (amount) => {
    setBuyAmount(amount);
    
    //closeModal();
    //navigate("/payment", { state: { amount: amount, price: price } });
  }
  const startTransaction = async() => {
    const sForm = httpsCallable(functions, 'startTransaction',
      { 'X-Firebase-AppCheck': getTokenAppCheck() });
      setLoading(true); 
      try{ 
          await sForm([{buyAmount:buyAmount}]);
          setBuyAmount(0);
          setBuyStep(0);
          closeModal();
          dispatch({type:'toast',payload:{toast_bg:'success', toast_header:'Success',
                            toast_show:true,   toast_message:'Transaction started successfully. Thank you',}})
      
        }catch(err){
          setBuyAmount(0);
          setBuyStep(0);
          closeModal();
          dispatch({type:'toast',payload:{toast_bg:'error', toast_header:'Error',
            toast_show:true,   toast_message:'An Error while starting transaction, please try again.',}})
          }finally
          {
            setLoading(false);
          }
        return false;
      
    }
    
    const getDetails=async()=>{
    
    const sForm = httpsCallable(functions, 'getDetails',
        { 'X-Firebase-AppCheck': getTokenAppCheck() });
       let result="";
        try{ 
            result = await sForm();
            return result.data;
        }catch(err){
          console.log(err);
          return false;
        }
  
    }
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, async(user) => {
        let appDetails=await getDetails();
        if(appDetails)
          {
             setSoldTokens(appDetails.sold.toLocaleString());
              setTotalTokens(appDetails.total.toLocaleString());
              setProgress((appDetails.sold*100)/appDetails.total);
              setPrice(appDetails.price);
              setStage(appDetails.stage);
              setStatus(appDetails.status);
              setDesc(appDetails.desc);
              setPendingTransactions(appDetails.pendingTransactions);
              setBalance(appDetails.balance.toLocaleString());
              setBalanceInUsdt((appDetails.balance*appDetails.price).toLocaleString());
              setIsRunning(appDetails.isRunning);

             
          }
        
        
        if (user) {
              await dispatch({type:'isLogon', payload:true}); 
              await dispatch({type:'user',payload:{'email':user.email,'nickname':appDetails.nickname,'wallet_address':appDetails.wallet_address,'balance':appDetails.balance, 'uid':user.uid}});
            }  
           else {
            // await storeUser(email,password);
              await dispatch({type:'isLogon', payload:false}); 
              await dispatch({type:'user',payload:{'email':'','nickname':'','wallet_address':'','balance':0, 'uid':''}});
              
          }
        });
        return () => unsubscribe();
  },[]);

  return (
    
    <Container fluid className="p-0">
      <Alert variant="dark" style={{ marginBottom: '5px', padding: '10px 20px' }}>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div style={{ fontWeight: 'bold' }}>Buy $TAC</div>
    {state.user.email && state.user.email.length > 0 ? (
      <div>
        Welcome <strong>{state.user.nickname}</strong> | <a href="#" onClick={Logout}>Logout</a>
      </div>
    ) : (
      <div>
        Welcome, Please <a href="/login">log in</a>
      </div>
    )}
  </div>
</Alert>
      <Row className="justify-content-center">
        <Col md={3} className="d-flex">
       
          <Card className="flex-fill mb-2" style={{
              backgroundImage: "linear-gradient(45deg, #2d4974 0%, #455e84 100%)",
              color: "white"
            }}>
            <Card.Body>
              <Row>
                <Col xs={3}>
                  <img
                    src="/icon.png"
                    alt="Token Logo"
                    className="img-fluid"
                  />
                </Col>
                <Col xs={9}>
                  <Card.Title className="mb-1">TOKEN BALANCE</Card.Title>
                  <Card.Text className="h3">{balance} TAC</Card.Text>
                </Col>
              </Row>
              <hr />
              <Row className="text-center">
                <Col>
                  <p className="mb-1">Your Contribution In</p>
                  <p>~{balanceInUsdt} USDT</p>
                  {pendingTransactions > 0 && (
                <p className="blink">You have pending transactions</p>
                 )}
                </Col>
                
              </Row>
             
             
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="d-flex">
       
       <Card className="flex-fill mb-2">
         <Card.Body>
           <div className="text-center">
             <p>
             <Card.Title className="mb-1">{stage} <Badge bg="success">{status}</Badge></Card.Title> 
             </p>
            
           <hr />
           <p>{desc}</p>
             <p>1 TAC = {price} USDT</p>
             <Button variant="primary" onClick={openModal} className="w-100" disabled={!isRunning}>
               Buy Token Now
             </Button>
           </div>
           <BuyModal isOpen={isModalOpen} onClose={closeModal}>
           {(() => {
        switch (buyStep) {
          case 0:
            return(
              
                <div className="d-flex flex-column">
                    <p>How much token do you want to buy?</p>
                            <button className="btn btn-primary mb-2" onClick={() => onBuy(20)}>20 USDT</button>
                            <button className="btn btn-primary mb-2" onClick={() => onBuy(50)}>50 USDT</button>
                            <button className="btn btn-primary mb-2" onClick={() => onBuy(100)}>100 USDT</button>
                            <button className="btn btn-primary mb-2" onClick={() => onBuy(1000)}>1000 USDT</button>
                            <button className="btn btn-primary" onClick={() => onBuy(5000)}>5000 USDT</button>
                {buyAmount > 0 ?
                <div className="text-center">
                  <hr />
                            <p>You will get {(buyAmount / price).toLocaleString()} TAC</p>
                  <Button variant="success" onClick={() => setBuyStep(1)} className="w-100">
                    Next
                  </Button>
                  </div>
                :null }
                </div>  
                 
                    
            );
          case 1:
            return (
              <div className="text-center">
               <p><h4><span style={{color:"green"}}><b>Ready to Buy.</b></span></h4></p>
               <p><h5>Please send {buyAmount.toLocaleString()} USDT to BEP-20 address below.</h5></p> 
           <p><span style={{color:"red"}}>This Transaction will expire on 1 hour.</span></p>
          
           <p>
           <QRCode
                        title="Toss a Coin"
                        value={state.user.wallet_address}
                        bgColor={qrback}
                        fgColor={qrfore}
                        size={qrsize === '' ? 0 : qrsize}
                    />
            
            </p>
           <p><span>{state.user.wallet_address}</span></p>
           
           <p> <button style={{borderRadius:15}}
  onClick={() => {
  navigator.clipboard.writeText(state.user.wallet_address);
  dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
											toast_show:true,   toast_message:'Copied to clipboard',}})
        }}>
 Copy to clipboard
</button></p>
<hr />
                            <p>You will get {(buyAmount / price).toLocaleString()} TAC</p>
                  <Button variant="success" onClick={() => startTransaction()} className="w-100">
                    Start Transaction
                  </Button>
</div>
            );
          default:
            return '';
          }
          })()} 
            </BuyModal>
         </Card.Body>
       </Card>
     </Col>
     <Col md={3} className="d-flex">
       
       <Card className="flex-fill mb-2">
         <Card.Body>
           <div className="text-center">
             <p>
             <Card.Title className="mb-1">Token Sale Progress</Card.Title> 
             <hr />
             </p>
             <ProgressBar now={progress} label={`${progress.toFixed(0)}%`} style={{ height: '20px' }} />
             <Row className="mt-1">
             <Col style={{ textAlign: 'right' }}>
               {soldtokens} / {totaltokens} TAC
             </Col>
           </Row>
           
           </div>
         </Card.Body>
       </Card>
     </Col>
      
  </Row>
  <Row className="justify-content-center">
  <Col md={6} className="d-flex">
          <Card className="flex-fill mb-2">
            <Card.Body>
              <Card.Title>How to buy TAC Token? <hr /></Card.Title>
              <div className="video-responsive text-center" style={{margin:'0 auto'}}>
          <iframe style={{maxWidth:"560px",minHeight:"250px"}} width="90%" height="auto" src="https://www.youtube.com/embed/nSBOltHnNJ4" title="How to buy Toss a Coin" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
           </div>
            </Card.Body>
          </Card>
      </Col>
  
      <Col md={6} className="d-flex">
          <Card className="flex-fill mb-2">
            <Card.Body className="text-center">
              <Card.Title>Stages and Vesting Plan<hr />
              </Card.Title>
            
              <Card.Text className="text-muted">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Stage Number</th>
                    <th>Price</th>
                    <th>Total Token</th>
                    <th>Vesting</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>1</td>
                      <td>0.002 USDT</td>
                      <td>30.000.000</td>
                      <td>10% at TGE and 6 months linear</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>0.0022 USDT</td>
                      <td>30.000.000</td>
                      <td>10% at TGE and 6 months linear</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>0.0024 USDT</td>
                      <td>30.000.000</td>
                      <td>10% at TGE and 6 months linear</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>0.0026 USDT</td>
                      <td>30.000.000</td>
                      <td>10% at TGE and 6 months linear</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>0.0028 USDT</td>
                      <td>30.000.000</td>
                      <td>10% at TGE and 6 months linear</td>
                    </tr>
                 </tbody>
              </table>
              <p>More information at : <a href="https://tossacoin.app/whitepaper.html">Toss a Coin Whitepaper</a></p>
              </Card.Text>
            
            </Card.Body>
          </Card>
      </Col>
  
    </Row>
    <Row className="justify-content-center">
    <Col md={9} className="d-flex">
          <Card className="flex-fill mb-2">
            <Card.Body className="text-center">
              <Card.Title>Your Wallet Details <hr />
              </Card.Title>
            
              {state.user.wallet_address && state.user.wallet_address.length > 0 ? (
              <Card.Text className="text-muted">
                <p>
                  <QRCode
                        title="Toss a Coin"
                        value={state.user.wallet_address}
                        bgColor={qrback}
                        fgColor={qrfore}
                        size={qrsize === '' ? 0 : qrsize}
                    />
                </p>  
                <p><span>{state.user.wallet_address} (BSC)</span></p>                 
                <p> <button style={{borderRadius:15}}
  onClick={() => {
  navigator.clipboard.writeText(state.user.wallet_address);
  dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
											toast_show:true,   toast_message:'Copied to clipboard',}})
        }}>
 Copy to clipboard
</button></p>
              </Card.Text>
            ) : (
              <Card.Text className="text-muted">
                Please <a href="/login">log in</a> to see your wallet details.
              </Card.Text>
            )}
            </Card.Body>
          </Card>
      </Col>
</Row>
    <div className={`modal ${loading ? 'show' : ''}`} style={{ display: loading ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <h5>Please wait...</h5>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Container>
    
  );
  
};

export default BuyTac;